exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-data-insight-js": () => import("./../../../src/pages/services/data-insight.js" /* webpackChunkName: "component---src-pages-services-data-insight-js" */),
  "component---src-pages-services-devops-js": () => import("./../../../src/pages/services/devops.js" /* webpackChunkName: "component---src-pages-services-devops-js" */),
  "component---src-pages-services-digital-marketing-js": () => import("./../../../src/pages/services/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-js" */),
  "component---src-pages-services-ecommerce-js": () => import("./../../../src/pages/services/ecommerce.js" /* webpackChunkName: "component---src-pages-services-ecommerce-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-seo-n-internet-marketing-js": () => import("./../../../src/pages/services/seo-n-internet-marketing.js" /* webpackChunkName: "component---src-pages-services-seo-n-internet-marketing-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-services-webdesign-and-development-js": () => import("./../../../src/pages/services/webdesign-and-development.js" /* webpackChunkName: "component---src-pages-services-webdesign-and-development-js" */),
  "component---src-pages-software-development-dubai-js": () => import("./../../../src/pages/software-development-dubai.js" /* webpackChunkName: "component---src-pages-software-development-dubai-js" */),
  "component---src-pages-terms-n-conditions-js": () => import("./../../../src/pages/terms-n-conditions.js" /* webpackChunkName: "component---src-pages-terms-n-conditions-js" */)
}

